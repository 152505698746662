import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import cookie from "react-cookie";
import FormHelperText from "@material-ui/core/FormHelperText";

import { dataSourceV2 } from "commons-ui/core/DataSource";

import "./CookieBanner.module.scss";

export const CookieBanner = () => {
  const [view, setView] = useState(cookie.load("cookie_banner") ? "accepted" : "");

  const onSetView = (value) => {
    setView(value)
    let expiresAt = new Date();
    expiresAt.setFullYear(expiresAt.getFullYear() + 1);
    if (value === "rejected") {
      cookie.save("cookie_banner", "rejected", { path: "/", expires: expiresAt });
    } else if (value === "accepted") {
      cookie.save("cookie_banner", "accepted", { path: "/", expires: expiresAt });
      if (window.initPixels) {
        window.initPixels()
      }
    }
  }

  const elem = (title, description) => {
    return (
      <div className="u-marginTop20">
        <div className="u-fontSize16 u-marginBottom5">
          {title}
        </div>
        <div className="">
              {description}
        </div>
      </div>
    )
    return (
      <FormControl
                            error={false}
                            className="u-marginTop12"
                            component="fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="secondary"
                                            onChange={(e) => null}
                                            checked={true}
                                            className="u-fontSize10"/>
                                    }
                                    className="u-fontSize10"
                                    label={title}
                                    />
                            </FormGroup>
                            <FormHelperText>
                                {description}
                            </FormHelperText>
                        </FormControl>
    )
  }

  if (view === "accepted" || view === "rejected") {
    return null
  }

  if (view === "preferences") {
    return (
      <span className={"cookie-banner u-xs-left0"}>
        <div className={"cookie-banner-text"}>
            <p>
          We use different types of cookies to optimize your experience on our website. Click on the categories below to learn more about their purposes. You may choose which types of cookies to allow and can change your preferences at any time. Remember that disabling cookies may affect your experience on the website. You can learn more about how we use cookies by visiting our Cookie Policy and Privacy Policy.
          </p>
          <div>
            {elem("Performance and Functionality Cookies", "These cookies are used to enhance the performance and functionality of our websites but are nonessential to their use. However, without these cookies, certain functionality (like videos) may become unavailable.")}
            {elem("Analytics and Customization Cookies", "These cookies collect information that can help us understand how our websites are being used. This information can also be used to measure effectiveness in our marketing campaigns or to curate a personalized site experience for you.")}
            {elem("Advertising Cookies", "These cookies are used to make advertising messages more relevant to you. They prevent the same ad from continuously reappearing, ensure that ads are properly displayed for advertisers, and in some cases select advertisements that are based on your interests.")}

            </div>
        </div>
        <div className="u-flexCenter u-justifyContentCenter u-marginTop10">

          {/*<Button aria-label="Close Cookie Banner" className="button-secondary" size="small" onClick={() => onSetView("")}>Close</Button>*/}
          <Button className="button-grey u-marginHorizontal10" size="small" onClick={() => onSetView("rejected")}>Decline All</Button>
          <Button className="button-secondary u-marginHorizontal10" size="small" onClick={() => onSetView("accepted")}>Allow All</Button>
      </div>
      </span>
    )
  }

  return (
    <span className={"cookie-banner u-xs-left0"}>
                <p className="u-marginTop15">We use essential cookies to make our site work. With your consent, we may also use non-essential cookies to improve user experience and analyze website traffic. By clicking “Accept,“ you agree to our website's cookie use as described in our <a className="link" href="https://piroshkybakery.com/policy-center/cookie-policy/" target="_blank" aria-label="COOKIE POLICY (opens in a new tab)">COOKIE POLICY</a> and <a className="link" href="https://piroshkybakery.com/policy-center/cookie-policy/" target="_blank" aria-label="PRIVACY POLICY (opens in a new tab)">PRIVACY POLICY</a>.</p>

      <div className="u-flexCenter u-justifyContentCenter u-marginTop10">
        <Button aria-label="Read More on specific Cookies" className="button-grey u-marginHorizontal10" onClick={() => onSetView("preferences")} size="small">Reject</Button>
        {/*<Button aria-label="Decline Cookies" className="button-secondary u-marginHorizontal10" onClick={() => onSetView("rejected")} size="small">Reject</Button>*/}
          <Button aria-label="Accept Cookies" className="button-secondary u-marginHorizontal10" onClick={() => onSetView("accepted")} size="small">Accept</Button>
          
      </div>
    </span>
  );
};
